import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { RightBridgeApiService } from '../../services/right-bridge-api.service';
import { SessionStorageService } from '../../services/session-storage.service';
import { ActivatedRoute } from '@angular/router';
import {
  GridComponent,
  DataBindingDirective,
  FilterService,
} from '@progress/kendo-angular-grid';
import {
  SortDescriptor,
  process,
  State,
  GroupDescriptor,
  CompositeFilterDescriptor,
  filterBy,
} from '@progress/kendo-data-query';
import { cloneDeep } from 'lodash';
import { UserPreferencesService } from '../../services/user-preferences.service';
import { CustomFiltersService } from '../../services/custom-filters.service';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-fia',
  templateUrl: './fia.component.html',
  styleUrls: ['./fia.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FiaComponent implements OnInit {
  @ViewChild(DataBindingDirective) directive;

  public gridData: any;
  public ogData: any;
  public gridColumns: any[] = [];
  public pageSize = 25;
  public pageSizeOptions = [25, 50, 75, 100];
  public skip = 0;
  public sort: SortDescriptor[] = [];
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  public group: GroupDescriptor[] = [];
  public hidden: string[] = [];
  public state: State = {
    skip: this.skip,
    take: this.pageSize,
  };
  public defaultValue: string = undefined;
  public defaults = {
    highCapRate_sp500: {
      group: [
        { field: 'FIARate$DeclaredRateCap', dir: 'desc' },
        { field: 'InvestProductFIA$IndexName', dir: 'asc' },
      ],
      filter: [
        {
          field: 'InvestProductFIA$IndexName',
          operator: 'eq',
          value: 'S&P 500',
        },
        {
          field: 'InvestProductFIA$CalculationPeriod',
          operator: 'eq',
          value: 'Annual',
        },
      ],
    },
    highCapRate_sp500_monthly: {
      group: [
        { field: 'FIARate$DeclaredRateCap', dir: 'desc' },
        { field: 'InvestProductFIA$IndexName', dir: 'asc' },
      ],
      filter: [
        {
          field: 'InvestProductFIA$IndexName',
          operator: 'eq',
          value: 'S&P 500',
        },
        {
          field: 'InvestProductFIA$CalculationPeriod',
          operator: 'eq',
          value: 'Monthly',
        },
      ],
    },
    spread_partic_sp500: {
      group: [
        { field: 'FIARate$ParticipationRate', dir: 'desc' },
        { field: 'FIARate$Spread', dir: 'asc' },
        { field: 'InvestProductFIA$IndexName', dir: 'asc' },
      ],
      filter: [
        {
          field: 'InvestProductFIA$IndexName',
          operator: 'eq',
          value: 'S&P 500',
        },
        // { field: 'FIARate$DeclaredRateCap', operator: 'eq', value: '0.00%' }
      ],
    },
    highCapRate_NonSp500: {
      group: [
        { field: 'FIARate$DeclaredRateCap', dir: 'desc' },
        { field: 'InvestProductFIA$IndexName', dir: 'asc' },
      ],
      filter: [
        {
          field: 'InvestProductFIA$IndexName',
          operator: 'neq',
          value: 'S&P 500',
        },
      ],
    },
    spread_partic_NonSp500: {
      group: [
        { field: 'FIARate$ParticipationRate', dir: 'desc' },
        { field: 'FIARate$Spread', dir: 'asc' },
        { field: 'InvestProductFIA$IndexName', dir: 'asc' },
      ],
      filter: [
        {
          field: 'InvestProductFIA$IndexName',
          operator: 'neq',
          value: 'S&P 500',
        },
        // { field: 'FIARate$DeclaredRateCap', operator: 'eq', value: '0.00%' }
      ],
    },
    monthlyCalc_index: {
      group: [
        { field: 'InvestProductFIA$CalculationPeriod', dir: 'asc' },
        { field: 'InvestProductFIA$IndexName', dir: 'asc' },
      ],
      filter: [
        {
          field: 'InvestProductFIA$CalculationPeriod',
          operator: 'eq',
          value: 'Monthly',
        },
      ],
    },
    annualCalc_index: {
      group: [
        { field: 'InvestProductFIA$CalculationPeriod', dir: 'asc' },
        { field: 'InvestProductFIA$IndexName', dir: 'asc' },
      ],
      filter: [
        {
          field: 'InvestProductFIA$CalculationPeriod',
          operator: 'eq',
          value: 'Annual',
        },
      ],
    },
    multiCalc_index: {
      group: [
        { field: 'InvestProductFIA$CalculationPeriod', dir: 'desc' },
        { field: 'InvestProductFIA$IndexName', dir: 'asc' },
      ],
      filter: [
        {
          field: 'InvestProductFIA$StrategyTerm',
          operator: 'neq',
          value: '1 year',
        },
      ],
    },
    highFixedRate: {
      group: [{ field: 'FIARate$FixedRate', dir: 'desc' }],
      filter: [
        {
          field: 'InvestProductFIA$CalculationPeriod',
          operator: 'neq',
          value: '0.00%',
        },
      ],
    },
    creditingMethod: {
      group: [{ field: 'FIARate$PerformanceTriggerRate', dir: 'desc' }],
      filter: [
        {
          field: 'InvestProductFIA$CreditingMethod',
          operator: 'eq',
          value: 'Performance Triggered',
        },
      ],
    },
  };
  public defaultList = [
    {
      label: 'Highest Cap Rate for S&P 500 Annual Calculation Period',
      value: 'highCapRate_sp500',
    },
    {
      label: 'Highest Cap Rate for S&P 500 Index Monthly Calculation Period',
      value: 'highCapRate_sp500_monthly',
    },
    {
      label: 'Spread with Participation Rate S&P 500 Index',
      value: 'spread_partic_sp500',
    },
    {
      label: 'Highest Cap Non S&P 500 Indexes',
      value: 'highCapRate_NonSp500',
    },
    {
      label: 'Spread with Participation Rate Non S&P 500 Indexes',
      value: 'spread_partic_NonSp500',
    },
    {
      label: 'Monthly Calculation Periods by Index',
      value: 'monthlyCalc_index',
    },
    {
      label: 'Annual Calculation Periods by Index',
      value: 'annualCalc_index',
    },
    {
      label: 'Multi-Year Calculation Periods by Index',
      value: 'multiCalc_index',
    },
    {
      label: 'Highest Fixed Rate',
      value: 'highFixedRate',
    },
    {
      label: 'Performance Triggered Rates',
      value: 'creditingMethod',
    },
  ];

  columnWidths = {
    AnnuityProduct$Carrier: 200,
    AnnuityProduct$Contract: 250,
    InvestProductFIA$ShortName: 300,
    FIARate$RateVersion: 200,
    InvestProductFIA$IndexName: 200,
    InvestProductFIA$CreditingMethod: 200,
  };

  cusip: string;
  cusipTitle;
  loading = true;
  loadedUserPrefs = false;
  filterData = {};

  constructor(
    private rbs: RightBridgeApiService,
    private ss: SessionStorageService,
    private route: ActivatedRoute,
    private prefSrvc: UserPreferencesService,
    private cstmFltrSrvc: CustomFiltersService
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params.cusip) {
        this.cusip = params.cusip;
      }
    });

    this.pageSize = this.ss.get('awShelfPageSize') || 25;
    this.state.take = this.ss.get('awShelfPageSize') || 25;
    this.getGridData();
  }

  getGridData() {
    this.rbs.FiaGridData('fia').subscribe(res => {
      const gd = res.results;
      gd.headers.forEach(x => {
        switch (x.RespVis) {
          case 'xs':
            x.RespVis = '';
            break;
          case 'sm':
            x.RespVis = '(min-width: 700px)';
            break;
          case 'md':
            x.RespVis = '(min-width: 1100px)';
            break;
          case 'lg':
            x.RespVis = '(min-width: 1200px)';
            break;
          case 'xl':
            x.RespVis = '(min-width: 1500px)';
            break;
        }
        if (Object.keys(this.columnWidths).includes(x.DataField)) {
          x.Width = this.columnWidths[x.DataField];
        } else if (!Object.keys(this.columnWidths).includes(x.DataField)) {
          x.Width = 100;
        }
      });

      this.gridColumns = gd.headers;
      this.ogData = gd.data;
      this.gridData = this.ogData;
      this.buildFilterOptions();

      if (this.cusip && this.cusip.length) {
        this.cusipFilter(this.cusip);
      } else {
        this.useDefault();
      }

      this.getUserDefinedColumns();
      this.loading = false;
    });
  }

  setHiddenColumns(reset = false) {
    setTimeout(() => {
      const columns = [...this.gridColumns];
      if (!this.loadedUserPrefs && !reset) {
        columns.forEach(x => {
          if (x.Hidden) {
            this.hidden.push(x.DataField);
          }
        });
        this.gridColumns = [...columns];
      } else {
        columns.forEach(x => {
          if (this.hidden.includes(x.DataField)) {
            x.Hidden = true;
          } else {
            x.Hidden = false;
          }
        });
        this.gridColumns = [...columns];
      }

      this.hidden.forEach(x => {
        this.hideColumn(x);
      });
    });
  }

  public groupChange(groups: GroupDescriptor[]): void {
    this.group = groups;
    this.state = {
      skip: this.skip,
      take: this.pageSize,
      group: this.group,
      sort: this.sort,
      filter: this.filter,
    };

    this.gridData = process(this.ogData, this.state);
  }

  public cusipFilter(cusip) {
    this.filter['filters'] = [
      {
        field: 'AnnuityProduct$AnnuityID',
        operator: 'eq',
        value: cusip,
      },
    ];
    this.gridData = process(this.ogData, { filter: this.filter });
  }

  updateHidden(ev) {
    this.hidden = [...ev.value];
    this.setStorage();
  }

  isHidden(columnName: string): boolean {
    return this.hidden.indexOf(columnName) > -1;
  }

  hideColumn(columnName: string): void {
    if (!this.isHidden(columnName)) {
      this.hidden.push(columnName);
      this.setStorage();
    }
  }

  toggleColumn(columnName: string): void {
    const hiddenColumns = this.hidden;

    if (!this.isHidden(columnName)) {
      hiddenColumns.push(columnName);
    } else {
      hiddenColumns.splice(hiddenColumns.indexOf(columnName), 1);
    }
  }

  showColumn(columnName: string): void {
    const hiddenColumns = this.hidden;

    if (this.isHidden(columnName)) {
      hiddenColumns.push(columnName);
    } else {
      hiddenColumns.splice(hiddenColumns.indexOf(columnName), 1);
    }
  }

  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.state = {
      skip: this.skip,
      take: this.pageSize,
      group: this.group,
      sort: this.sort,
      filter: this.filter,
    };

    this.gridData = process(this.ogData, this.state);
  }

  setStorage() {
    this.ss.set('hiddenFiaColumns', this.hidden);
  }

  useDefault(type?: MatSelectChange) {
    this.cusip = null;

    if (!type || type.value === 'clear') {
      this.group = [];
      if (this.filter) {
        this.filter['filters'] = [];
      }
      this.sort = [];
      this.defaultValue = undefined;
      this.directive.skip = 0;
      this.directive.state.filter.filters = [];
      this.buildFilterOptions();
      this.setHiddenColumns(true);
    } else {
      this.group = this.defaults[type.value].group
        ? this.defaults[type.value].group
        : [];
      this.filter['filters'] = this.defaults[type.value].filter
        ? this.defaults[type.value].filter
        : [];
      this.sort = this.defaults[type.value].sort
        ? this.defaults[type.value].sort
        : [];
    }

    this.state = {
      skip: this.skip,
      take: this.pageSize,
      group: this.group,
      sort: this.sort,
      filter: this.filter,
    };

    this.gridData = process(this.ogData, this.state);
  }

  externalColumnsUpdated(ev) {
    // This is a hack to stop the grid from scrolling to the right
    const gridContentScroll = document.querySelector('.k-grid-content');
    const oldScrollPosition = gridContentScroll.scrollLeft;

    const headerWrapContentScroll = document.querySelector(
      '.k-grid-header-wrap'
    );
    const headerWrapPosition = headerWrapContentScroll.scrollLeft;
    setTimeout(() => {
      gridContentScroll.scrollLeft = oldScrollPosition;
      headerWrapContentScroll.scrollLeft = headerWrapPosition;
    });

    const fullList = this.gridColumns.map(x => {
      return x.DataField;
    });
    const hidden = fullList.filter(y => !ev.includes(y));

    this.updateHidden({ value: hidden });
    this.saveUserDefinedColumns();
  }

  getUserDefinedColumns() {
    const fullList = this.gridColumns.map(x => {
      return x.DataField;
    });

    this.prefSrvc.getColumns().subscribe(x => {
      if (x && x.fia && x.fia.length > 0) {
        this.loadedUserPrefs = true;

        const hidden = fullList.filter(y => x && x.fia && !x.fia.includes(y));
        this.updateHidden({ value: hidden });
      }
      this.setHiddenColumns();
    });
  }

  saveUserDefinedColumns() {
    const fullList = this.gridColumns.map(x => {
      return x.DataField;
    });
    const visible = fullList.filter(x => !this.hidden.includes(x));

    this.prefSrvc.saveColumns({ fia: visible }).subscribe();
  }

  public filterValueChange(
    values: any[],
    field,
    filterService: FilterService
  ): void {
    filterService.filter({
      filters: values.map(value => ({
        field: field,
        operator: 'eq',
        value,
      })),
      logic: 'or',
    });
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    const passedFilterNames = [];
    const passedFilterDatas = {};

    // Extract passed filter data for trickle down column filter option narrowing
    filter.filters.map(filterObj => {
      const filterName = filterObj['filters'][0]['field'];
      passedFilterNames.push(filterName);
      passedFilterDatas[filterName] = this.filterData[filterName];
    });

    this.gridData = filterBy(this.ogData, filter);
    this.filterData = this.cstmFltrSrvc.buildColumnFilterOptions(
      filterBy(this.ogData, filter),
      this.gridColumns
    );

    // Replace filter options with trickled down options
    passedFilterNames.map(filterName => {
      this.filterData[filterName] = passedFilterDatas[filterName];
    });
  }

  buildFilterOptions() {
    this.filterData = this.cstmFltrSrvc.buildColumnFilterOptions(
      this.ogData,
      this.gridColumns
    );
  }

  exportToExcel(grid: GridComponent): void {
    const newGrid = cloneDeep(grid);
    newGrid.saveAsExcel();
  }

  public excelData = () => {
    const fullData = this.ogData.map(a => ({ ...a }));
    fullData.forEach(el => {
      Object.keys(el).forEach(x => {
        if (
          x === 'FIARate$DeclaredRateCap' ||
          x === 'FIARate$FixedRate' ||
          x === 'FIARate$ParticipationRate' ||
          x === 'FIARate$PerformanceTriggerRate' ||
          x === 'FIARate$PremiumBonus' ||
          x === 'FIARate$Spread'
        ) {
          el[x] = `${this.round(el[x] * 100, 2)}%`;
        }
        if (el[x] == -9.99 || el[x] == -999) {
          el[x] = 'N/A';
        }
      });
    });
    return process(fullData, {});
  };

  round = (num: number, places: number) => {
    return +(Math.round(Number(num + 'e+' + places)) + 'e-' + places);
  };

  dataStateChange(ev) {
    this.ss.set('awShelfPageSize', ev.take);
  }
}
