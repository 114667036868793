import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormArray,
  AbstractControl,
} from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { FieldType, FormlyConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { SessionStorageService } from '../../../services/session-storage.service';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { UnifiedFlowService } from '../../../unified-flow/unified.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-unified-shell',
  templateUrl: './unified-shell.component.html',
  styleUrls: ['./unified-shell.component.scss'],
})
export class UnifiedShellComponent
  extends FieldType<FormlyFieldConfig>
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('stepper', { static: false }) unifiedStepper: MatStepper;
  @ViewChild('stepperContainer', { static: false }) container: ElementRef;

  errorMessage$;
  formErrors = {};
  touchedChanged$;
  visibilityChanged$;
  visitedSteps = {};
  nextButtonText: string;
  app;
  state;
  debouncer: Subject<any> = new Subject<any>();
  stepIndex = 0;
  unsubscribe: Subject<any> = new Subject<any>();
  triggerValidateObs;

  constructor(
    private config: FormlyConfig,
    private ss: SessionStorageService,
    private unfFlowSvc: UnifiedFlowService,
    private router: Router,
    private cdRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.app = this.ss.get('currentApp');

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const url = event.urlAfterRedirects;
        const urlSplit = url ? url.split('/') : null;
        const step = url ? urlSplit[urlSplit.length - 1] : this.stepIndex;

        if (step != this.stepIndex && isNaN(+step)) {
          // this.stepIndex = 9999;
        } else if (step != this.stepIndex) {
          this.changeStep(+step);
        }
      }
    });

    switch (this.app) {
      case 'iw':
        this.nextButtonText = 'Next';
        break;
      case 'aw':
        // this.nextButtonText = 'Next';
        this.nextButtonText = 'Evaluate Annuities';
        break;
      case 'pp':
        this.nextButtonText = 'Review Results';
        break;
      case 'cp':
        this.nextButtonText = 'View Client Needs';
        break;
      case 'li':
        this.nextButtonText = 'Next';
        break;
      default:
        this.nextButtonText = 'Submit';
        break;
    }

    this.ss.storageChangeExternal.subscribe(chng => {
      if (this.unifiedStepper && chng.key === 'currentStep') {
        const newStep = this.ss.get('currentStep');

        this.changeStep(newStep);
      }
    });

    const currentStep = this.ss.get('currentStep');
    setTimeout(() => {
      this.changeStep(currentStep);
    });

    this.field?.form?.valueChanges
      .pipe(
        takeUntil(this.unsubscribe),
        distinctUntilChanged(),
        debounceTime(50)
      )
      .subscribe(() => {
        for (let index = 0; index <= currentStep; index++) {
          this.getErrors(this.field?.fieldGroup);
        }
        this.formatSteps();
      });
  }

  ngAfterViewInit() {
    const currentStep = this.ss.get('currentStep');

    for (let index = 0; index <= currentStep; index++) {
      this.getErrors(this.field?.fieldGroup);
    }
    this.formatSteps();

    // if (!this.triggerValidateObs) {
    //   this.triggerValidateObs = this.unfFlowSvc.triggerValidateExternal;
      // this.triggerValidateObs
        // .pipe(takeUntil(this.unsubscribe))
        // .subscribe(() => {
        //   // this.validateFields(this.form);
        // });
    // }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  changeStep(newStep) {
    if (
      this.unifiedStepper.selectedIndex != newStep &&
      !isNaN(newStep) &&
      newStep != 9999
    ) {
      this.unifiedStepper.linear = false;

      // This is a fun hack
      // https://github.com/angular/components/issues/8479#issuecomment-444063732
      this.stepIndex = newStep;
      this.cdRef.detectChanges();

      setTimeout(() => {
        this.unifiedStepper.linear = true;
      });
    } else if (newStep === 9999) {
      this.stepChange({
        previouslySelectedIndex: this.unifiedStepper.selectedIndex,
        selectedIndex: this.unifiedStepper.selectedIndex,
      });
    }

    this.getErrors(this.field.fieldGroup);

    const count =
      newStep === 9999 ? this.unifiedStepper.selectedIndex : newStep;
    for (let index = 0; index <= count; index++) {
      this.getErrors(this.field.fieldGroup);
      // this.unifiedStepper.steps.toArray()[index].interacted = true;
    }
  }

  getErrors(fg) {
    this.formErrors = {};

    const visible = fg?.filter(x => !x.hide);
    visible?.forEach((group, idx) => {
      this.formErrors[`step${idx}`] = false;

      //
      // TODO: Refactor this hot mess into a looping function and fix the .hide stuff
      //

      group.fieldGroup.forEach(field => {
        if (field.formControl && field.formControl.errors && !field.hide) {
          this.formErrors[`step${idx}`] = true;
        } else if (
          (field.formControl instanceof FormGroup ||
            field.formControl instanceof FormArray) &&
          !field.hide
        ) {
          field.fieldGroup.forEach(grpField => {
            if (
              grpField.formControl &&
              grpField.formControl.errors &&
              !grpField.hide
            ) {
              this.formErrors[`step${idx}`] = true;
            } else if (
              grpField.formControl &&
              (grpField.formControl instanceof FormGroup ||
                grpField.formControl instanceof FormArray) &&
              !grpField.hide
            ) {
              grpField.fieldGroup.forEach(subField => {
                if (
                  subField.formControl &&
                  (subField.formControl instanceof FormGroup ||
                    subField.formControl instanceof FormArray) &&
                  !subField.hide
                ) {
                  subField.fieldGroup.forEach(subGroupField => {
                    if (
                      subGroupField.formControl &&
                      (subGroupField.formControl instanceof FormGroup ||
                        subGroupField.formControl instanceof FormArray) &&
                      !subGroupField.hide
                    ) {
                      subGroupField.fieldGroup.forEach(nestedLoopingField => {
                        if (
                          nestedLoopingField.formControl &&
                          nestedLoopingField.formControl.errors &&
                          !nestedLoopingField.hide
                        ) {
                          this.formErrors[`step${idx}`] = true;
                        }
                      });
                    } else if (
                      subField.formControl &&
                      subField.formControl.errors &&
                      !subField.hide
                    ) {
                      this.formErrors[`step${idx}`] = true;
                    }
                  });
                } else if (
                  subField.formControl &&
                  subField.formControl.errors &&
                  !subField.hide
                ) {
                  this.formErrors[`step${idx}`] = true;
                }
              });
            }
          });
        }
      });
    });
  }

  checkStepValidity(idx) {
    return this.formErrors[`step${idx}`];
  }

  stepChange(ev) {
    const visible = this.field.fieldGroup.filter(x => !x.hide);
    this.visitedSteps[`step${ev.previouslySelectedIndex}`] = true;
    this.validateFields(visible[ev.previouslySelectedIndex].fieldGroup);
    this.formatSteps();
  }

  formatSteps() {
    setTimeout(() => {
      const steps = this.unifiedStepper.steps.map(stp => stp);
      const filteredList = this.field.fieldGroup.filter(x => !x.hide);
      const stepsOutput = steps.map((stp, idx) => {
        const step = {
          visited: stp.interacted,
          completed: stp.completed,
          valid: !this.formErrors[`step${idx}`],
          state: stp.state,
          label: this.formatStepLabel(filteredList[idx]),
        };

        return step;
      });
      this.unfFlowSvc.setSteps(stepsOutput);
    });
  }

  formatStepLabel(label) {
    let labelAttr;
    if (label) {
      if (label.props.label.includes('Source - {{')) {
        labelAttr = label.props.label.split('{{')[1].replace('}}', '');
        return `Source - ${this.model[labelAttr]}`;
      } else if (
        label.props.label.includes('{{') &&
        label.props.label.includes('}}')
      ) {
        labelAttr = label.props.label.split('{{')[1].replace('}}', '');
        return this.model[labelAttr];
      } else {
        return label.props.label;
      }
    }
    return '';
  }

  validateFields(fg, group?) {
    if (Array.isArray(fg) && !group) {
      fg.forEach(field => {
        const control = field;
        if (control instanceof FormArray) {
          control.markAsTouched();
          control.updateValueAndValidity();
        } else if (
          control instanceof FormGroup ||
          control instanceof FormArray
        ) {
          this.validateFields(control, true);
        } else if (control.type === 'custom-rankorder') {
          control.formControl.markAsTouched();
        } else {
          this.validateFields(control.formControl, true);
        }
      });
    } else if (group && !(fg instanceof FormControl)) {
      Object.keys(fg.controls).forEach(field => {
        const control = fg.get(field);
        if (control instanceof FormControl) {
          control.markAsTouched();
          control.updateValueAndValidity();
          fg.markAsTouched();
          fg.updateValueAndValidity();
        } else if (
          control instanceof FormGroup ||
          control instanceof FormArray
        ) {
          control.markAsTouched();
          control.updateValueAndValidity();
          this.validateFields(control, true);
        }
      });
    } else if (fg instanceof FormGroup || fg instanceof FormArray) {
      fg.markAsTouched();
      fg.updateValueAndValidity();
      this.validateFields(fg, true);
    } else {
      fg.markAsTouched();
      fg.updateValueAndValidity();
    }
    this.getErrors(this.field.fieldGroup);
  }
}

/**
 * Extract arguments of function
 */
export type ArgumentsType<F> = F extends (...args: infer A) => any ? A : never;

/**
 * Creates an object like O. Optionally provide minimum set of properties P which the objects must share to conform
 */
type ObjectLike<O extends object, P extends keyof O = keyof O> = Pick<O, P>;

/* Extract a touched changed observable from an abstract control
 * @param control AbstractControl like object with markAsTouched method
 */
export const extractTouchedChanges = (
  control: ObjectLike<AbstractControl, 'markAsTouched' | 'markAsUntouched'>
): Observable<boolean> => {
  const prevMarkAsTouched = control.markAsTouched;
  const prevMarkAsUntouched = control.markAsUntouched;

  const touchedChanges$ = new Subject<boolean>();

  function nextMarkAsTouched(
    ...args: ArgumentsType<AbstractControl['markAsTouched']>
  ) {
    touchedChanges$.next(true);
    prevMarkAsTouched.bind(control)(...args);
  }

  function nextMarkAsUntouched(
    ...args: ArgumentsType<AbstractControl['markAsUntouched']>
  ) {
    touchedChanges$.next(false);
    prevMarkAsUntouched.bind(control)(...args);
  }

  control.markAsTouched = nextMarkAsTouched;
  control.markAsUntouched = nextMarkAsUntouched;

  return touchedChanges$;
};
