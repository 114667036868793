import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { RightBridgeApiService } from '../../services/right-bridge-api.service';
import { SessionStorageService } from '../../services/session-storage.service';
import { TagsEvaluationService } from '../../services/tags-evaluation.service';
import { WarnDialogComponent } from '../../warn-dialog/warn-dialog.component';
import { isEmpty } from 'lodash';
import { InvestmentWizardService } from '../services/investment-wizard-service.service';
import { ValidationDialogComponent } from '../validation-dialog/validation-dialog.component';
import * as Highcharts from 'highcharts';
import { ConsolidatedReportModalComponent } from '../../shared-components/consolidated-report-modal/consolidated-report-modal.component';
import { CustomReportModalComponent } from '../../shared-components/custom-report-modal/custom-report-modal.component';
import { UnifiedFlowService } from '../../unified-flow/unified.service';

@Component({
  selector: 'app-iw-result',
  templateUrl: './validate-result.component.html',
  styleUrls: ['./validate-result.component.scss'],
})
export class ValidateResultComponent implements OnInit, AfterViewInit {
  @Input() hasSummaryModule;

  id: string;
  crumb = 'iw-results';
  bridges = [];
  loading = false;
  name: string;
  hidePrint = false;
  externalLink;
  externalAudit;
  crossApp;
  awButtonText = 'Choose Annuities';
  ppButtonText = 'Return to Product Profiler';
  liButtonText = 'Select Insurance Products';
  bridgeTypes = [
    'portfolio',
    'program',
    'altProgram',
    'Plans',
    'altPlan',
    'funds',
    'ALT',
    'ALTopt',
  ];
  Highcharts: typeof Highcharts = Highcharts;
  printDefaults = {
    details: false,
    alternative: false,
    includeFundAlternativesChart: false,
  };
  rights = [];
  altsExpanded = false;
  altsPlansExpanded = false;
  altsOptionsExpanded = false;
  noSelection = false;
  placeholder = null;
  programType = null;
  switchCompareHeaders = [];
  selectedAltsHeader = '';
  otherAltsHeader = '';
  moduleNotes = {
    required: false,
    prompt: '',
    include: false,
    note: '',
  };
  altGridExpand = false;
  altGridPageSize = 2;

  expandedBridges: string[] = [];

  constructor(
    public dialog: MatDialog,
    private rbs: RightBridgeApiService,
    private params: ActivatedRoute,
    private route: Router,
    private ss: SessionStorageService,
    private tes: TagsEvaluationService,
    private iws: InvestmentWizardService,
    private unfFlowSvc: UnifiedFlowService
  ) {}

  ngOnInit() {
    this.params.params.subscribe(params => {
      this.id = params.id;
    });

    const globals = this.ss.get('globals') || {};
    this.rights = this.ss.get('rights');
    this.externalLink = globals.standardPostBack || null;
    this.externalAudit = globals.dynamicPostBack || null;
    this.crossApp = globals.crossApp || null;
    this.awButtonText = globals.AWButtonText
      ? globals.AWButtonText
      : this.awButtonText;
    this.ppButtonText = globals.PPButtonText
      ? globals.PPButtonText
      : this.ppButtonText;
    this.liButtonText = globals.LIButtonText
      ? globals.LIButtonText
      : this.liButtonText;

    if (globals.GlobalsGroup && globals.GlobalsGroup.UnitNotesSettings) {
      this.moduleNotes.include =
        globals['GlobalsGroup'].UnitNotesSettings.IncludeSingleIWNote;
      this.moduleNotes.required =
        globals['GlobalsGroup'].UnitNotesSettings.SingleIWNoteRequired;
      this.moduleNotes.prompt =
        globals['GlobalsGroup'].UnitNotesSettings.SingleIWNotePrompt;
    }

    this.unfFlowSvc.setNoteValidity({ app: 'iw', valid: true });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.initData();
    }, 250);
  }

  expand(event) {
    const el = this.closestByClass(event.target, 'product');
    el.classList.toggle('expanded');
  }

  closestByClass(el, clazz) {
    while (!el.classList.contains(clazz)) {
      el = el.parentNode;
      if (!el) {
        return null;
      }
    }
    return el;
  }

  initData() {
    const save = this.unfFlowSvc.getSaving();
    this.unfFlowSvc.setLoading(true);
    if (save) {
      setTimeout(() => {
        this.initData();
      }, 250);
    } else {
      this.setLoading(true);
      this.getResults(this.id);
    }
  }

  getResults(profile, processData = undefined) {
    this.setLoading(true);

    if (processData) {
      this.processPortfolioData(processData);
    } else {
      this.iws.processPortfolio(profile).subscribe(data => {
        this.processPortfolioData(data);
      });
    }
  }

  processPortfolioData(data) {
    if (data.InvestmentWizard && data.InvestmentWizard.ValidationNote) {
      this.moduleNotes.note = data.InvestmentWizard.ValidationNote;
    }

    const brdgTps = [];
    this.name = `${data.ClientPerson.FirstName} ${data.ClientPerson.LastName}`;
    this.printDefaults = {
      details: data.UnitPrintDefaults.FundDetails,
      alternative: data.UnitPrintDefaults.ProgramDetails,
      includeFundAlternativesChart: data.UnitPrintDefaults.FundAlternatives,
    };
    this.bridges = data.bridges;
    const headers = {
      compareHeadersUIT: [...data.CompareHeadersUIT],
      compareHeaders529: [...data.CompareHeaders529],
      compareHeaders: [...data.CompareHeaders],
    };

    let invalidNotes = 0;
    this.noSelection = !data.SelectedProgramData.FundSelectionProgram;
    this.placeholder = data.UnitQuestions.StdReportNotesPlaceholder;

    if (data.SelectedFunds?.length > 0) {
      this.ss.set('selectedFunds', {});
    }

    this.bridges = this.bridges.filter(x => {
      if (
        x.BridgeType == 'portfolio' ||
        x.BridgeType == 'program' ||
        x.BridgeType == 'Plans' ||
        x.BridgeType == 'ALT' ||
        x.BridgeType == 'GF' ||
        x.BridgeType == 'TT' ||
        x.BridgeType == 'AA'
      ) {
        return true;
      }
    });

    this.bridges.forEach(x => {
      x.negativeReason = false;
      if (x.ComplianceChart) {
        x.ComplianceChart = JSON.parse(x.ComplianceChart);
      }

      if (!brdgTps.includes('portfolio') && x.BridgeType == 'portfolio') {
        brdgTps.push('portfolio');
      }

      if (
        !brdgTps.includes('ALT') &&
        x.BridgeType === 'ALT' &&
        x.SelectedBridge.toLowerCase() === 'yes'
      ) {
        brdgTps.push('ALT');
      }

      if (
        !brdgTps.includes('ALTopt') &&
        x.BridgeType === 'ALT' &&
        x.SelectedBridge.toLowerCase() === 'no'
      ) {
        brdgTps.push('ALTopt');
      }

      if (
        !brdgTps.includes('Plans') &&
        x.BridgeType == 'Plans' &&
        x.SelectedBridge.toLowerCase() == 'yes'
      ) {
        brdgTps.push('Plans');
      } else if (
        !brdgTps.includes('altPlan') &&
        x.BridgeType == 'Plans' &&
        x.SelectedBridge.toLowerCase() == 'no'
      ) {
        brdgTps.push('altPlan');
      }

      if (
        !brdgTps.includes('program') &&
        x.BridgeType == 'program' &&
        x.SelectedBridge.toLowerCase() == 'yes'
      ) {
        brdgTps.push('program');
      } else if (
        !brdgTps.includes('altProgram') &&
        x.BridgeType == 'program' &&
        x.SelectedBridge.toLowerCase() == 'no'
      ) {
        brdgTps.push('altProgram');
      }

      if (
        !brdgTps.includes('funds') &&
        (x.BridgeType == 'GF' || x.BridgeType == 'TT' || x.BridgeType == 'AA')
      ) {
        brdgTps.push('funds');
      }

      if (!x.DisplayName) {
        x.DisplayName = x.outline.sections.ShortName;
      }

      if (x.ProgramID) {
        x.programDescription = x.outline.sections;
        x.programDescription.Description =
          x.vars.CurrentProgramDefinition.ProgramDescription;
        x.programDescription.Instructions =
          x.vars.CurrentProgramDefinition.ProgramInstructions;
        x.programDescription.DescriptionBullets = x.vars
          .CurrentProgramDefinition.ProgramBullets
          ? x.vars.CurrentProgramDefinition.ProgramBullets.split(' ~ ')
          : null;
      }

      if (x.vars.AlternateFundDataOut) {
        x.alternatives = this.buildAlternatives(
          x.vars.AlternateFundDataOut,
          headers,
          x.FundID
        );
      } else if (x.vars.AlternateFundData) {
        x.alternatives = this.buildAlternatives(
          x.vars.AlternateFundData,
          headers,
          x.FundID
        );
      }

      if (
        x.BridgeType == 'GF' ||
        x.BridgeType == 'TT' ||
        x.BridgeType == 'AA'
      ) {
        x.fundDetails = [];
        x.BridgeType = 'funds';
        this.iws.fundDetails(x.vars.CurrentFundData[0].FundID).subscribe(f => {
          x.fundDetails = f.results;
        });
      }

      if (x.BridgeType == 'program' && x.SelectedBridge.toLowerCase() == 'no') {
        x.BridgeType = 'altProgram';
      }

      if (x.BridgeType == 'Plans' && x.SelectedBridge.toLowerCase() == 'no') {
        x.BridgeType = 'altPlan';
      }

      if (x.trueRules) {
        x.trueRules.forEach(e => {
          e.explanation = this.tes.evalTags(e.explanation);
          e.headline = this.tes.evalTags(e.headline);
          x.negativeReason =
            e.reasonType == 'Negative' ? true : x.negativeReason;
        });
      }
      const requiredNotes =
        x.vars.CurrentBridgeNote.Required == 2 ||
        (x.vars.CurrentBridgeNote.Required == 3 && x.negativeReason);
      const notesEntered =
        x.vars.CurrentBridgeNote && x.vars.CurrentBridgeNote.NoteText
          ? x.vars.CurrentBridgeNote.NoteText.length > 0
          : false;

      x.notesValid =
        (requiredNotes && notesEntered) || !requiredNotes ? true : false;

      if (!x.notesValid) {
        invalidNotes++;
      }
    });

    if (data.SwitchCompareHeaders) {
      this.switchCompareHeaders = data.SwitchCompareHeaders;
    }
    this.unfFlowSvc.setNoteValidity({ app: 'iw', valid: true });
    if (invalidNotes > 0 && !this.moduleNotes.include) {
      this.unfFlowSvc.setNoteValidity({ app: 'iw', valid: false });
    } else if (this.moduleNotes.include && this.moduleNotes.note) {
      this.unfFlowSvc.setNoteValidity({ app: 'iw', valid: true });
    } else if (this.moduleNotes.include && !this.moduleNotes.note) {
      this.unfFlowSvc.setNoteValidity({ app: 'iw', valid: false });
    }

    this.bridgeTypes = this.bridgeTypes.filter(value =>
      brdgTps.includes(value)
    );

    if (
      this.bridgeTypes.includes('ALT') &&
      (data.ALTProgramConfigurableText.ResultsRAAsHeader ||
        data.ALTProgramConfigurableText.ResultsSelectedHeader)
    ) {
      this.selectedAltsHeader =
        data.ALTProgramConfigurableText.ResultsSelectedHeader;
      this.otherAltsHeader = data.ALTProgramConfigurableText.ResultsRAAsHeader;
    }

    if (data.InvestmentWizard.AssessmentType == 2) {
      this.crumb = 'iw-validate-results';
    }

    if (data.ResultsDisplay) {
      Object.keys(data.ResultsDisplay).forEach(x => {
        if (data.ResultsDisplay[x]) {
          switch (x) {
            case 'PortfolioCheckOpen':
              this.expandedBridges.push('PortfolioCheck');
              break;

            case 'ExchangeSwapOpen':
              this.expandedBridges.push('ExchangeSwap');
              break;

            case 'PortfolioCheckOpen':
              this.expandedBridges.push('PortfolioCheck');
              break;

            case 'ProgramCheckOpen':
              this.expandedBridges.push('Program');
              break;

            case 'PlanCheckOpen':
              this.expandedBridges.push('FiveTwentyNinePlans');
              break;

            case 'InvestmentCheckOpen':
              this.expandedBridges.push('Funds');
              break;

            // case 'AltsCheckOpen':
            //   this.expandedBridges.push('AlternativeInvestments');
            //   break;
          }
        }
      });
    }

    this.setLoading(false);
  }

  buildAlternatives(rawData, rawHeaders, fundId) {
    const fundData = rawData.filter(z => {
      return z.Compare;
    });

    const idx = fundData.findIndex(z => {
      return z.FundID == fundId;
    });

    if (idx > 0) {
      const fnd = fundData[idx];
      fundData.splice(idx, 1);
      fundData.unshift(fnd);
    }

    const tableData = { headers: [], rows: [] };
    let headers;

    if (fundData[0].FundType && fundData[0].FundType.toLowerCase() === 'uit') {
      headers = rawHeaders.compareHeadersUIT;
    } else if (
      fundData[0].FundType &&
      fundData[0].FundType.toLowerCase() === '529'
    ) {
      headers = rawHeaders.compareHeaders529;
    } else {
      headers = rawHeaders.compareHeaders;
    }
    headers.forEach((el, i) => {
      if (i == 0) {
        tableData.headers.push({ title: '', field: 'Name' });
      } else if (i >= 1) {
        const field = {};
        field['Name'] = el.Description;
        tableData.rows.push(field);
      }
    });

    fundData.forEach(el => {
      tableData.headers.push({
        title: el.DisplayName,
        field: el.FundID,
        fundSelected: el.FundID == fundId,
        fundID: el.FundID,
      });

      headers.forEach((x, i) => {
        if (!tableData.rows[i]) {
          tableData.rows[i] = {};
        }

        if (i > 0) {
          tableData.rows[i - 1][el.FundID] =
            el[x.VarName];
        }
      });
    });

    return tableData;
  }

  getFundDetail(symbol) {
    let results;
    this.iws.fundDetails(symbol).subscribe(x => {
      results = x.results;
    });

    return results;
  }

  report() {
    this.setLoading(false);
    const dialogRef = this.dialog.open(ValidationDialogComponent, {
      panelClass: 'validate-dialog',
      data: {
        hideDetails: false,
        id: this.id,
        defaults: this.printDefaults,
        noSelection: this.noSelection,
        placeholder: this.placeholder,
      },
      autoFocus: false,
      maxHeight: '90vh',
    });
    dialogRef.afterClosed();
  }

  externalAction() {
    let link = this.externalLink.link || null;
    const silentPrint = this.externalLink.silentPrint,
      pb = this.externalLink.includePostBack || null,
      includePID = this.externalLink.includePID,
      newTab = this.externalLink.RedirectSameTab ? '_self' : '_blank';

    if (includePID) {
      link += this.id;
    }

    if (silentPrint && pb) {
      this.setLoading(true);
      this.iws
        .getReport(
          this.id,
          this.printDefaults.details,
          this.printDefaults.alternative,
          this.printDefaults.includeFundAlternativesChart,
          silentPrint
        )
        .subscribe(z => {
          this.rbs.postback(this.id).subscribe(() => {
            this.setLoading(false);
            window.open(link, newTab);
          });
        });
    } else if (pb) {
      this.setLoading(true);
      this.rbs.postback(this.id).subscribe(() => {
        this.setLoading(false);
        window.open(link, newTab);
      });
    } else if (silentPrint) {
      this.setLoading(true);
      this.iws
        .getReport(
          this.id,
          this.printDefaults.details,
          this.printDefaults.alternative,
          this.printDefaults.includeFundAlternativesChart,
          silentPrint
        )
        .subscribe(() => {
          this.setLoading(false);
          window.open(link, newTab);
        });
    } else {
      window.open(link, newTab);
    }
  }

  externalAuditAction() {
    const silentPrint = this.externalAudit.silentPrint,
      newTab = this.externalAudit.RedirectSameTab ? '_self' : '_blank',
      silentPrintPp = this.externalAudit.silentPrintPP,
      customReportSolution =
        this.externalAudit.postbackSolution &&
        this.externalAudit.postbackSolution.length > 0
          ? this.externalAudit.postbackSolution
          : null;
    let externalModal;

    this.setLoading(true);

    try {
      externalModal = JSON.parse(this.externalAudit.postBackModalName);
    } catch (error) {
      console.warn('Not valid json for modal: ', error);
    }

    if (externalModal && externalModal.ModalName.toLowerCase() === 'pershing') {
      let runModal = false;

      this.rbs.getPershingGroupProfile(this.id).subscribe(res => {
        runModal = res.PershingModal.AskForRRCode;

        if (runModal) {
          let dialogRef = this.dialog.open(CustomReportModalComponent, {
            panelClass: 'warn-dialog',
            minWidth: '50vw',
            data: {
              profile: this.id,
              name: externalModal.ModalName,
              headline: externalModal.Title,
              content: externalModal.Body,
              confirm: 'Submit',
            },
          });

          dialogRef.afterClosed().subscribe(result => {
            dialogRef = null;
            if (result != 'cancel') {
              this.rbs
                .extPostbackData(this.id, null, customReportSolution)
                .subscribe(x => {
                  if (x && x.results != null && x.results.length > 0) {
                    const link = x.results;
                    this.setLoading(false);
                    window.open(link, newTab);
                  } else {
                    let dialogRef = this.dialog.open(WarnDialogComponent, {
                      panelClass: 'warn-dialog',
                      data: {
                        headline: 'Warning',
                        content:
                          'The service you are trying to connect to is not currently available. Please report this to your system administrator and try again later.',
                        confirm: 'OK',
                        hideCancel: true,
                      },
                    });
                    dialogRef.afterClosed().subscribe(() => {
                      dialogRef = null;
                      this.setLoading(false);
                    });
                  }
                });
            } else {
              this.setLoading(false);
              console.warn('User opted out of submission');
            }
          });
        } else {
          this.rbs
            .extPostbackData(this.id, null, customReportSolution)
            .subscribe(x => {
              if (x && x.results != null && x.results.length > 0) {
                const link = x.results;
                this.setLoading(false);
                window.open(link, newTab);
              } else {
                let dialogRef = this.dialog.open(WarnDialogComponent, {
                  panelClass: 'warn-dialog',
                  data: {
                    headline: 'Warning',
                    content:
                      'The service you are trying to connect to is not currently available. Please report this to your system administrator and try again later.',
                    confirm: 'OK',
                    hideCancel: true,
                  },
                });
                dialogRef.afterClosed().subscribe(() => {
                  dialogRef = null;
                  this.setLoading(false);
                });
              }
            });
        }
      });
    } else if (
      externalModal &&
      externalModal.ModalName.toLowerCase() != 'pershing'
    ) {
      let dialogRef = this.dialog.open(WarnDialogComponent, {
        panelClass: 'warn-dialog',
        data: {
          name: externalModal.ModalName,
          headline: externalModal.Title,
          content: externalModal.Body,
          confirm: 'Submit',
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        dialogRef = null;
        if (result != 'cancel') {
          this.rbs
            .extPostbackData(this.id, result, customReportSolution)
            .subscribe(x => {
              if (x && x.results != null && x.results.length > 0) {
                const link = x.results;
                this.setLoading(false);
                window.open(link, newTab);
              } else {
                let dialogRef = this.dialog.open(WarnDialogComponent, {
                  panelClass: 'warn-dialog',
                  data: {
                    headline: 'Warning',
                    content:
                      'The service you are trying to connect to is not currently available. Please report this to your system administrator and try again later.',
                    confirm: 'OK',
                    hideCancel: true,
                  },
                });
                dialogRef.afterClosed().subscribe(() => {
                  dialogRef = null;
                  this.setLoading(false);
                });
              }
            });
        } else {
          this.setLoading(false);
          console.warn('User opted out of submission');
        }
      });
    } else if (
      this.externalAudit.postBackModalName &&
      this.externalAudit.postBackModalName.length > 0
    ) {
      let dialogRef = this.dialog.open(ConsolidatedReportModalComponent, {
        panelClass: 'consolidated-report',
        data: { profile: this.id, silentPrint: true },
        minWidth: '80vw',
        minHeight: '95vh',
        maxHeight: '95vh',
      });

      dialogRef.afterClosed().subscribe(result => {
        dialogRef = null;
        this.rbs
          .extPostbackData(this.id, result, customReportSolution)
          .subscribe(x => {
            if (x && x.results != null) {
              const link = x.results;
              this.setLoading(false);
              window.open(link, newTab);
            } else {
              const message = x.errorMessage
                ? x.errorMessage
                : 'The service you are trying to connect to is not currently available. Please report this to your system administrator and try again later.';

              let dialogRef = this.dialog.open(WarnDialogComponent, {
                panelClass: 'warn-dialog',
                data: {
                  headline: 'Warning',
                  content: message,
                  confirm: 'OK',
                  hideCancel: true,
                },
              });
              dialogRef.afterClosed().subscribe(() => {
                dialogRef = null;
                this.setLoading(false);
              });
            }
          });
      });
    } else {
      if (silentPrintPp) {
        this.rbs.silentPrintPpReport(this.id);
      }

      this.iws
        .getReport(
          this.id,
          this.printDefaults.details,
          this.printDefaults.alternative,
          this.printDefaults.includeFundAlternativesChart,
          silentPrint
        )
        .subscribe(z => {
          this.rbs.extPostback(this.id, customReportSolution).subscribe(x => {
            if (x.results != null) {
              const link = x.results;
              this.setLoading(false);
              window.open(link, newTab);
            } else {
              const message = x.errorMessage
                ? x.errorMessage
                : 'The service you are trying to connect to is not currently available. Please report this to your system administrator and try again later.';

              let dialogRef = this.dialog.open(WarnDialogComponent, {
                panelClass: 'warn-dialog',
                data: {
                  headline: 'Warning',
                  content: message,
                  confirm: 'OK',
                  hideCancel: true,
                },
              });
              dialogRef.afterClosed().subscribe(() => {
                dialogRef = null;
                this.setLoading(false);
              });
            }
          });
        });
    }
  }

  handleNotesValidation(ev, item) {
    if (!this.moduleNotes.include) {
      item.notesValid = ev;
      this.unfFlowSvc.setNoteValidity({
        app: 'iw',
        valid: !(this.bridges.filter(x => !x.notesValid).length > 0),
      });
    } else {
      this.unfFlowSvc.setNoteValidity({
        app: 'iw',
        valid: ev,
      });
    }
  }

  updateModuleNote(ev) {
    this.moduleNotes.note = ev;
  }

  isTableChart(chart) {
    return Object.keys(chart.Data).length > 0;
  }

  goCrossApp(app) {
    const pb = this.crossApp.includePostBack || null;
    const silentPrint = this.crossApp.silentPrint || null;

    if (silentPrint && pb) {
      this.setLoading(true);
      this.iws
        .getReport(
          this.id,
          this.printDefaults.details,
          this.printDefaults.alternative,
          this.printDefaults.includeFundAlternativesChart,
          silentPrint
        )
        .subscribe(z => {
          this.rbs.postback(this.id).subscribe(() => {
            this.setLoading(false);
            this.route.navigate(['/' + app + '/questionnaire/' + this.id]);
          });
        });
    } else if (pb && !silentPrint) {
      this.setLoading(true);
      this.rbs.postback(this.id).subscribe(() => {
        this.setLoading(false);
        this.route.navigate(['/' + app + '/questionnaire/' + this.id]);
      });
    } else if (silentPrint && !pb) {
      this.setLoading(true);
      this.iws
        .getReport(
          this.id,
          this.printDefaults.details,
          this.printDefaults.alternative,
          this.printDefaults.includeFundAlternativesChart,
          silentPrint
        )
        .subscribe(z => {
          this.setLoading(false);
          this.route.navigate(['/' + app + '/questionnaire/' + this.id]);
        });
    } else {
      this.route.navigate(['/' + app + '/questionnaire/' + this.id]);
    }
  }

  setLoading(state) {
    this.loading = state;
    this.unfFlowSvc.setLoading(state);
  }

  toggleAlternatives(type) {
    if (type === 'plans') {
      this.altsPlansExpanded = !this.altsPlansExpanded;
    } else if (type === 'alts') {
      this.altsOptionsExpanded = !this.altsOptionsExpanded;
    } else {
      this.altsExpanded = !this.altsExpanded;
    }
  }

  asIsOrder() {
    return 1;
  }

  returnZero() {
    return 0;
  }

  isEmpty(obj) {
    return isEmpty(obj);
  }

  alternativeSwap(fund, altID) {
    let dialogRef = this.dialog.open(WarnDialogComponent, {
      panelClass: 'warn-dialog',
      data: {
        headline: 'Attention!',
        content: 'Are you sure you want to switch your selected investment?',
        confirm: 'Confirm',
        hideCancel: false,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      dialogRef = null;
      if (result === 'continue') {
        this.setLoading(true);
        const selectedFund = fund.vars.AlternateFundDataOut.find(
          listedFund => listedFund.FundID === fund.FundID
        );
        const altFund = fund.vars.AlternateFundDataOut.find(
          listedFund => listedFund.FundID === altID
        );

        const altSwap = {
          Client: {
            ID: this.id,
          },
          SelectedSwapDestination: {
            FundID: altFund.FundID,
            ExpenseRatio: altFund.ExpenseRatio * 1,
            Name: altFund.Name,
            Amount: fund.Amount * 1,
          },
          SelectedSwapSource: {
            FundID: fund.FundID,
            ExpenseRatio: selectedFund.ExpenseRatio * 1,
            Name: fund.Name,
            Amount: fund.Amount * 1,
          },
        };

        this.iws.processAlternative(altSwap).subscribe(resp => {
          this.processPortfolioData(resp);
        });
      }
    });
  }

  toggleGrid(ev, totalItems: number) {
    if (this.altGridExpand) {
      this.altGridExpand = false;
      this.altGridPageSize = 2;
    } else {
      this.altGridExpand = true;
      this.altGridPageSize = totalItems;
    }
  }
}
