<div class="page-container case-management-container container">
  <div class="breadcrumb">
    <button
      mat-button
      (click)="nav('unified')"
      *hasPermission="'!ToolsLandingPage'"
    >
      <mat-icon>home</mat-icon>
      <span class="text-container">Home</span>
    </button>
    <button
      mat-button
      (click)="nav('tools')"
      *hasPermission="'ToolsLandingPage'"
    >
      <mat-icon>home</mat-icon>
      <span class="text-container">Home</span>
    </button>
  </div>

  <h1 class="header col-12">
    Case Management
    <button
      mat-button
      color="primary"
      class="pull-right"
      (click)="createAdHocCase()"
      *hasPermission="'CreateCMCaseAdHoc'"
    >
      <mat-icon>add_circle_outline</mat-icon>Create Case
    </button>
  </h1>

  @if (!hideGrid) {
    <div class="row mb-0 mx-1">
      <app-grid-filters
        class="col-12"
        (filtersUpdated)="updateFilters($event)"
        [filters]="externalFilters"
        [reset]="resetFilters"
        [preexistingFilters]="filter"
        [manualSearch]="true"
        (triggerSearch)="searchResults()"
        [searchButtonText]="'Search Results'"
      ></app-grid-filters>
    </div>

    <div class="row mb-0 mx-1 grid-controls">
      <!-- <app-grid-column-filter
        [columns]="gridData.headers"
        (columnsUpdated)="externalColumnsUpdated($event)"
        class="ml-auto mr-2"
      ></app-grid-column-filter> -->

      <button mat-button color="primary" class="mr-0" (click)="reset()">
        <mat-icon class="mr-2">replay</mat-icon>Reset
      </button>
    </div>

    <kendo-grid
      class="col-12 mb-5"
      #caseManagementGrid="kendoGrid"
      [data]="gridData"
      [skip]="state.skip"
      [pageSize]="state.take"
      [pageable]="true"
      [columnMenu]="{ columnChooser: false }"
      [filter]="filter"
      [filterable]="false"
      [sortable]="{ allowUnsort: true, mode: 'multiple' }"
      [sort]="sort"
      (sortChange)="sortChange($event)"
      (pageChange)="pageChange($event)"
    >
      <kendo-grid-column width="75" title="">
        <ng-template kendoGridCellTemplate let-dataItem>
          <button
            mat-icon-button
            class="fs-button"
            (click)="reviewCase(dataItem)"
            color="primary"
            matTooltip="Review Case"
          >
            <mat-icon>rate_review</mat-icon>
          </button>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column [width]="55"
        ><ng-template kendoGridCellTemplate let-dataItem
          ><div
            ><mat-icon
              class="icon-{{ dataItem.Color }}"
              [ngClass]="{ 'icon-grey': !dataItem.Color }"
              >{{ dataItem.Icon }}</mat-icon
            ></div
          ></ng-template
        ></kendo-grid-column
      >
      <ng-template ngFor [ngForOf]="gridColumns" let-column>
        <kendo-grid-column
          [field]="column.DataField"
          [hidden]="isHidden(column.DataField)"
          [title]="column.Label"
          [sortable]="column.Sort"
          [groupable]="column.Group"
          [media]="column.RespVis"
          [width]="125"
        >
          <ng-template
            kendoGridFilterMenuTemplate
            let-filter="filter"
            let-filterService="filterService"
          >
            <kendo-multiselect
              style="width: 99%; margin-top: -30px"
              [data]="filterData[column.DataField]"
              textField="display"
              valueField="value"
              [valuePrimitive]="true"
              [value]="filter | filterValues"
              (valueChange)="
                filterValueChange($event, column.DataField, filterService)
              "
              [fillMode]="'outline'"
              placeholder="Filter By..."
            ></kendo-multiselect>
          </ng-template>
          @if (column.Type === 'integer') {
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem[column.DataField] | number: '1.0-0' }}
            </ng-template>
            <ng-template
              kendoGridGroupHeaderTemplate
              let-group
              let-field="field"
              let-value="value"
            >
              {{ value | number: '1.0-0' }}
            </ng-template>
          }
          @if (column.Type === 'perc') {
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem[column.DataField] | percent }}
            </ng-template>
            <ng-template
              kendoGridGroupHeaderTemplate
              let-group
              let-field="field"
              let-value="value"
            >
              {{ value | percent }}
            </ng-template>
          }
          @if (column.Type === 'perc2') {
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem[column.DataField] | percent: '1.2' }}
            </ng-template>
            <ng-template
              kendoGridGroupHeaderTemplate
              let-group
              let-field="field"
              let-value="value"
            >
              {{ value | percent: '1.2' }}
            </ng-template>
          }
          @if (column.Type === 'curr') {
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem[column.DataField] | currency }}
            </ng-template>
            <ng-template
              kendoGridGroupHeaderTemplate
              let-group
              let-field="field"
              let-value="value"
            >
              {{ value | currency }}
            </ng-template>
          }
          @if (column.Type === 'date') {
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem[column.DataField] | date: 'short' }}
            </ng-template>
          }
          @if (column.DataField === 'notes') {
            <ng-template kendoGridCellTemplate let-dataItem>
              <span [innerHtml]="dataItem[column.DataField]"></span>
            </ng-template>
          }
          @if (column.DataField === 'SubmittedBy') {
            <ng-template kendoGridCellTemplate let-dataItem>
              <span [innerHtml]="dataItem['SubmittedByName']"></span>
            </ng-template>
          }
        </kendo-grid-column>
      </ng-template>
      <ng-template
        kendoPagerTemplate
        let-totalPages="totalPages"
        let-currentPage="currentPage"
      >
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-numeric-buttons
          [buttonCount]="buttonCount"
        ></kendo-pager-numeric-buttons>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-input></kendo-pager-input>
        <kendo-pager-info></kendo-pager-info>
        <kendo-pager-page-sizes
          [pageSizes]="pageSizeOptions"
        ></kendo-pager-page-sizes>
      </ng-template>
    </kendo-grid>
  }

  <ngx-loading [show]="loading"></ngx-loading>
</div>
